.video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px; height: 0; overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.ant-modal{
    width: auto !important;
}

.videoPlayer .ant-modal-header{
    padding: 0px !important;
    border-bottom: 0px;
}


@media only screen and (max-width: 768px) {
    .react-player{
       height: 100% !important;
       width: 100% !important;
    }
}