/*
.ant-card-cover{
    overflow: hidden;
}
.ant-card-cover img {
    max-width: 100%;
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.ant-card-cover:hover img {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
*/

.container .title{
    color: #1a1a1a;
    text-align: center;
    margin-bottom: 10px;
  }

  .content {
    cursor: pointer;
    position: relative;
    width: 95%;
    max-width: 400px;
    margin: auto;
    overflow: hidden;
    min-height: 425px;
    max-height: 425px;
    text-align: center;
  }

  .content .content-overlay {
    background: rgba(0,0,0,0.7);
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
    z-index: 1;
  }

  .content:hover .content-overlay{
    opacity: 1;
  }

  .content-image{
    width: 100%;
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }

  .content-image:hover{
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .content-details {
    position: absolute;
    text-align: center;
    padding-left: 1em;
    padding-right: 1em;
    width: 100%;
    top: 50%;
    left: 50%;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
    color: #fff;
    z-index:2;
  }

  .content:hover .content-details{
    top: 50%;
    left: 50%;
    opacity: 1;
  }

  .content-details h3{
    color: #fff;
    font-weight: 900;
    letter-spacing: 0.15em;
    margin-bottom: 0.5em;
    text-transform: uppercase;
    font-size: 18px;
  }

  .content-details strong{
    color: rgba(6, 252, 88, 0.933);
    font-size: 16px;
  }

  .content-details p{
    color: #fff;
    font-size: 0.8em;
  }

  .fadeIn-bottom{
    top: 80%;
  }

  .movieRating{
    text-align: right;
    font-size: 16px;
    font-weight: bold;
    position: absolute;
    color: #fff;
    opacity: 0.9;
    width: 100%;
    padding-right: 10px;
    background: linear-gradient(to left,rgb(91,102,244) 10%,rgba(64,64,64,0) 70%);
  }
  .similarMovies{
    margin-bottom: 10px;
    padding: 0px !important;
    text-align: center;
  }
  .similarMovies .content{
    display: inline-block;
    min-height: 350px;
    max-height: 350px;
  }

  .ant-rate{
    font-size: 16px !important;
  }

  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .content{
      width: 100%;
      min-height: 295px;
      max-height: 295px;
    }
    .moviecard, .content{
      margin-bottom: 0px !important;
      /* padding-right: 5px !important; */
      width:98%;
    }
    .similar .content{
      min-height: 290px !important;
    }
    .castMovies .content{
      min-height: 255px !important;
    }
  }
