.movieDetails{
    background: #fff;
    width: 1170px;
    min-height: 800px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    opacity: 0.9;
    -webkit-box-shadow: 0px 0px 18px 10px rgba(61,61,61,1);
    -moz-box-shadow: 0px 0px 18px 10px rgba(61,61,61,1);
    box-shadow: 0px 0px 18px 10px rgba(61,61,61,1);
    border-radius: 6px;
    margin-bottom: 80px;
}
.movieName{
    font-family: 'Roboto Condensed', sans-serif;
    margin-bottom: 10px;
}
.moviePoster{
    text-align: center;
    min-height: 350px !important;
}
.moviePoster img{
    object-fit: cover;
    max-width:100%;
    border-radius: 6px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.12),
            0 2px 4px 0 rgba(0,0,0,0.08);
}
.movieTagline, .overview{
    font-family: 'Lato', sans-serif;
}
.overview{
    font-size: 16px;
}
.prodCompanies{
    margin-top: 20px;
}
.prodCompanies a{
    font-size: 16px;
}
.actorPic{
    height: 80;
    width: 100px;
    border-radius: 50%;
}
.cast{
    text-align: center;
}
.cast a, .cast a:hover{
    color: #000;
}
.cast img{
    border: 2px solid rgb(168, 157, 157);
    padding: 2px;
    max-width: 100%;
    margin-bottom: 5px;
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.12),
            0 2px 4px 0 rgba(0,0,0,0.08);
}
.cast img:hover {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.12),
            0 2px 4px 0 rgba(0,0,0,0.08);
}
.charName{
    color: #4e4e4e;
}

.ant-breadcrumb{
    padding-left: 8px !important;
    padding-top: 20px !important;
}

.ant-comment-content-author span{
    font-size: 16px !important;
}
.ant-comment-content-detail{
    font-size: 14px !important;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .movieDetails{
      width: 100%;
      padding: 5px;
    }
    h1.ant-typography, .ant-typography h1{
        margin-top: 10px;
        margin-bottom: 5px !important;
        font-size: 30px !important;
    }
    h2.ant-typography, .ant-typography h2{
        font-size: 24px !important;
    }
    .movieName, .movieTagline {
        text-align : center;
    }

    .tags{
        text-align: center;
    }
    .prodCompanies img{
        text-align: center;
        margin-bottom: 5px;
        display: block;
    }
  }