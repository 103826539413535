.personProfilePic{
    /* width: 170px;
    height: 170px;
    border-radius: 50%; */
    width:170px;
    height:170px;
    border-radius:50%;
    border:4px solid #eee;
    background-position: center;
    background-repeat: no-repeat;
    background-size:auto 150%;
    position: absolute;
    top: -100px;
    -webkit-box-shadow: 0px 0px 10px 2px rgba(61,61,61,0.55);
    -moz-box-shadow: 0px 0px 10px 2px rgba(61,61,61,0.55);
    box-shadow: 0px 0px 10px 2px rgba(61,61,61,0.55);
}

.personProfile{
    min-height: 100px;
}

.ant-breadcrumb{
    margin-top: -20px !important;
}

/* .personProfilePic:hover{
    border-color: rgb(184, 183, 183);
} */

/* .personMeta{
    font-weight: bold;
} */

ul.personMeta { padding-left: 0px !important; font-size: 16px; }
ul.personMeta li { list-style: none; display: inline;}
ul.personMeta li:after { content: " \00b7"; padding: 0px 5px; font-weight: bold; }
ul.personMeta li:last-child:after { content: none; }

.personProfile h4{
    position: absolute;
    top: -60px;
}

.actorDetails{
    background: #fff;
    /* width: 1170px; */
    /* min-height: 800px; */
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    box-shadow : 0 15px 30px 0 rgba(0,0,0,.11), 0 5px 15px 0 rgba(0,0,0,.08);
    border-radius: 6px;
    margin-bottom: 80px;
}

.actorDetails{
    margin-top: 100px;
}

/* .castMovies .content{
    min-height: auto;
} */

.castMovies .content:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
}
.castMovies .content {
    /* display: inline-block;  */
    min-height: 270px;
    max-height: 270px;
}

@media only screen and (max-width: 768px) {
    .personProfilePic {
        left: 25%;
        top: -250px;
        margin-top: 10px;
    }
    .actorDetails{
        padding-top: 150px;
    }
    .personProfile h4{
        width: 100%;
        text-align: center;
        margin-bottom: 0px !important;
        top: -50px
    }
    .lightbox-container img{
        width: 43% !important;
    }
}