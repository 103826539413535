.dummyHeader{
    z-index: 1;
    position:sticky;
    top: 0;
    width: 100%;
    overflow: auto;
    background: #001529;
}

.ant-layout-header{
    position: "fixed";
    z-index: 1;
    width: "100%";
    padding: 0px 0px 70px 0px !important;
    max-width: 1170px;
    margin: 0 auto;
}

.logo{
    display: inline-block;
}
.logo img{
    width: 130px;
    padding-right: 10px;
}

.appMenu .ant-menu-item{
    padding-left: 0px !important;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected{
    background-color: transparent !important;
}

@media only screen and (max-width: 768px) {
  .logo img{
    padding-left: 20px;
    width: 100px;
  }
}